@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #fff;
  --item-border: #e2e3e7;
  --plate-border: #e2e3e7;
  --item-background: #fff;
  --item-disabled-background: #f6f6f6;
  --item-secondary-background: #efefef;
  --dropdown-search-background: #f8f9fa;
  --section-border: #e2e3e7;
  --main-text: #111314;
  --secondary-text: #64686f;
  --border-secondary: #f3f3f3;
  --scrollbar-color: rgb(17 19 20 / 40%);
  --separator-color: #979797;
  --widget-border: #0054b8;
  --safari-scroll-background: #fff;

  //intercom color
  --intercom-color: #1e55b2;

  //menu text colors
  --menu-item: rgb(255 255 255 / 60%);
  --menu-item-hover: #fff;
  --menu-icon: unset;
  --menu-icon-hover: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(2691%) hue-rotate(7deg) brightness(105%) contrast(102%);

  //icons
  --icon-background: #1e55b2;
  --icon-color: unset;
  --black-icon: #200e32;
  --grey-text-icons: #767676;
  --icon-danger-background: #cc3030;
  --icon-danger-color: unset;
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(24%) sepia(91%) saturate(1381%) hue-rotate(202deg) brightness(93%) contrast(94%);

  //wallet types
  --fiat-type: rgb(0 175 255);
  --crypto-type: rgb(0 60 255);
  --saving-type: rgb(1 5 138);
  --market-chart: #1e55b2;

  // cards
  --card-text: #fff;
  --card-text-title: #fff;
  --card-cvv: #111314;
  --logo-width: 23.6em; // only in em
  --fields-card: unset;
  --card-logo: unset;
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #edf3fc;
  --notification-background: #eef4ff;
  --gradient-color1: #143052;
  --gradient-color2: #1e55ae;
  --main-red: #cc3030;
  --grey: #efefef;
  --grey-background: #f6f6f6;
  --grey-easy: #d9d9d9;
  --grey-primary: #888989;
  --light-red: #f9e6e6;
  --light-orange: #fbefe1;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #e5f5ed;
  --light-blue: #1e55b2;
  --orange: #e17c05;
  --extra-light: #f7faff;
  --primary-text: #1e55b2;
  --hover-primary: #0d89ec;
  --button-primary: #1e55b2;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(180deg, var(--gradient-color1) 0%, var(--gradient-color2) 100%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(21%) sepia(89%) saturate(2352%) hue-rotate(212deg) brightness(87%) contrast(82%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  // sizes
  --auth-logo-height: 46px;
  --dashboard-logo-width: 146px;
  --dashboard-logo-mobile-width: 36.39px;
  --auth-banner-color: #fff;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}

@media (max-width: $table-sm-breakpoint) {
  :root {
    --menu-item-hover: #111314;
    --menu-item: rgb(30 85 178 / 100%);
    --menu-icon: brightness(0) saturate(100%) invert(24%) sepia(78%) saturate(1875%) hue-rotate(202deg) brightness(90%) contrast(86%);
    --menu-icon-hover: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);
  }
}
